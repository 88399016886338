<template>
<div>
  <b-card no-body>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col cols="8">
          <b-form-group>
            <b-form-select v-model="selected" :options="options"/>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button
            variant="primary"
            @click="New()" >
            Thêm {{this.options.filter(i => i.value==this.selected)[0].text}}
          </b-button>
        </b-col>
        <!-- Per Page -->

        <!-- Search -->
        <b-col cols="12">
          <vue-good-table :columns="fields" :rows="items"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:perPage
        }"
      >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  @click="Edit(props.row,props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="Delete(props.row,props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="perPage"
              :options="['10','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ totalItem }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalItem"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
        </b-col>

      </b-row>

    </div>
    
  </b-card>
  <b-modal size="lg" id="modal-edit" :title="getTitle()" @ok="handleOk">
    <validation-observer ref="modalEdit">
      <b-row>
        <b-col :cols="i.cols != null?i.cols:6" v-for="i in fields.filter(i => i.field!=='action')" :key="i.field">
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="( !i.autoIncrement && (i.field=='name' || i.field=='id' || i.required))?'required':''" v-if="i.inputType==null">
            <b-form-group :label="i.label" >
              <b-form-input :disabled="(i.field==='id' || i.disabled) && editMode || i.autoIncrement" :state="errors.length > 0 ? false:null" v-model="currentItem[i.field]" :placeholder="i.label"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider :name="'\''+i.label+'\''" #default="{ errors }" :rules="(i.field=='name' || i.field=='id' || i.required)?'required':''" v-else>
            <b-form-group :label="i.label">
              <Select2 :state="errors.length > 0 ? false:null" :placeholder="i.label" v-model="currentItem[i.field]" :options="select2[i.inputType]"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip,BFormSelect } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import '@core/scss/vue/libs/vue-good-table.scss';
import Select2 from 'v-select2-component';

export default {
  components: {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,ValidationProvider, ValidationObserver,BFormSelect,VueGoodTable,Select2
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.fields = this.options[0].fields;
    useJwt.get('masterData/Country', {}).then(response =>{ this.select2.country = response.data.result;});
    this.fetchGroup();
  },
  watch: {
    perPage: function(n,o){
      this.fetchGroup()
    },
    searchQuery: function(n,o){
      this.fetchGroup()
    },
    selected: function(n,o){
      this.fields = this.options.filter(i => i.value==this.selected)[0].fields;
      this.fetchGroup()
    }
  },
  methods: {
    getTitle(){
      if (this.editMode){
        return 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text
      }else{
        return 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text
      }
    },
    fetchGroup: function() {
      useJwt.get('masterData/'+this.selected, {
          params: {
            search: this.searchQuery,
            page: 1,
            pageSize: this.perPage
          },
        })
        .then(response =>{
          this.items = response.data.result;
          console.log(this.items)
          this.totalItem = response.data.meta.total
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách '+this.options.filter(i => i.value===this.selected)[0].text,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.editMode=true;
      this.modal_title= "Cập nhật "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.editMode=false;
      this.modal_title= "Thêm mới "+this.options.filter(i => i.value===this.selected)[0].text
      this.$bvModal.show('modal-edit')
    },
    async handleOk(bvModalEvt){
      bvModalEvt.preventDefault()
      var validate = await this.$refs.modalEdit.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      if (!this.editMode){
        useJwt.post('masterData?objectType='+this.selected, this.currentItem)
        .then(response =>{
          this.fetchGroup();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi thêm mới '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.put('masterData?objectType='+this.selected,this.currentItem)
        .then(response =>{
          this.fetchGroup();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá dữ liệu '+this.selected, {title: 'Please Confirm',size: 'sm',})
      if (res){
        useJwt.delete(`masterData?objectType=${this.selected}&id=${this.currentItem.id}&code=${this.currentItem.code}`, {})
        .then(response =>{
          this.fetchGroup();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá '+this.options.filter(i => i.value===this.selected)[0].text+' thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá '+this.options.filter(i => i.value===this.selected)[0].text,icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
  },
  data() {
    return {
      items: [],
      editMode:false,
      totalItem:0,
      permisItems: [],
      perPage:10,
      searchQuery:"",
      searchTerm: '',
      currentItem:{},
      selected: 'Country',
      select2:{
        country: [],
        cont_group:[
          {id:'Unit',text:'Unit'},
          {id:'time',text:'time'},
          {id:'van truck',text:'van truck'},
          {id:'Container truck',text:'Container truck'},
          {id:'packaging',text:'packaging'},
        ]
      },
      options: [
        {
          value:'Country',
          text:'Quốc gia',
          fields: [
            {
              field: 'code',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Mã Quốc gia',
              required:true,
              disabled: true,
            },
            {
              field: 'name',
              filterOptions: {enabled: true, placeholder: ''},
              label: 'Tên Quốc gia'
            },
            { field: 'action', label: 'Actions' }
          ],
        },
      ],
      fields: [],
      required
    }
  },
}
</script>
<style>
.select2-selection--single{
    border: 1px solid #2B938C;
    border-radius: 1.5rem;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMkI5MzhDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.75em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>